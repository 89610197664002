import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const GoogleRecaptchaProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={String(process.env.SITE_KEY)}>
      {children}
    </GoogleReCaptchaProvider>
  );
};
