import "./src/styles/common.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import { GoogleRecaptchaProvider } from "src/components/Provider/GoogleRecaptchaProvider";

const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container);
    root.render(<GoogleRecaptchaProvider>{element}</GoogleRecaptchaProvider>);
  };
};

export { replaceHydrateFunction };
